<template>
  <div id="countdownPlayerList" class="has-text-black-bis" :class="[{darkmode: darkmode},{offlineMode: offlineMode}]">
    <div class="top">
      <div class="nextRoundPlayers">
        <player-avatar v-for="(player, i) in players" :player="player" :key="player.id" :scale="offlineMode ? 2 : 2.5" :showUsername="true"
          :class="[i < options.queue.minPlayers ? 'minPlayer' : 'availablePlayer']" class="jugador"/>
        <!-- Jugadores placeholders -->
        <player-avatar-placeholder v-for="i in options.queue.maxPlayers - players.length" :key="i" :scale="offlineMode ? 2 : 2.5"
          :class="[i + players.length <= options.queue.minPlayers ? 'minPlayer' : 'availablePlayer']" class="jugador"/>
      </div>
    </div>
    <div class="bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
import PlayerAvatar from '../PlayerAvatar.vue';
import PlayerAvatarPlaceholder from '../PlayerAvatarPlaceholder.vue';
import './screenQueue.scss';

export default {
  components: {
    PlayerAvatar,
    PlayerAvatarPlaceholder
  },
  props: {
    title: {
      type: String,
      default: "Prepare to play!",
    },
    darkmode: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    offlineMode(){
      return this.$store.state.setup.others?.offlineMode
    },
    options() {
      return this.$store.getters["space/config"].experience.component[0];
    },
    players() {
      return this.$store.state.space.players.filter(x => !x.queue)
    },
  }
};
</script>

<style scoped>
.offlineMode {
  top: 534px !important;
}
</style>

